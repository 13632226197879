import React from 'react';
import Img from 'gatsby-image';
import { combine } from '../../style/utils';
import styles from './WrappedImage.module.scss';

const WrappedImage = ({ src, alt, className, wrapperClass, style, fluid }) => (
  <div className={combine(styles.imgWrapper, wrapperClass)} style={style}>
    {(() => {
      if (fluid) {
        return (
          <Img
            fluid={fluid}
            alt={alt}
            className={combine(styles.img, className)}
          />
        );
      } else {
        return (
          <img src={src} alt={alt} className={combine(styles.img, className)} />
        );
      }
    })()}
  </div>
);

export default WrappedImage;
