import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';

import { combine, bg, border, txt, flex, lists } from '../style/utils';
import { getImage } from '../utils/queryUtils';

// Components
import HeroSpace from '../components/HeroSpace/HeroSpace';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import WrappedImage from '../components/WrappedImage/WrappedImage';
import styles from './about-us.module.scss';

const AboutUs = ({ data }) => {
  const traits = [
    {
      icon: getImage(data.traitIcons, 'magnet.png'),
      alt: 'Magnet icon',
      text: 'Energetic',
    },
    {
      icon: getImage(data.traitIcons, 'clipboard.png'),
      alt: 'Clipboard icon',
      text: 'Experienced',
    },
    {
      icon: getImage(data.traitIcons, 'lightbulb.png'),
      alt: 'Lightbulb icon',
      text: 'Innovative',
    },
    {
      icon: getImage(data.traitIcons, 'bubbles.png'),
      alt: 'Bubbles icon',
      text: 'Transparent',
    },
  ];

  const team = [
    {
      photo: getImage(data.profileImages, 'ed.jpg'),
      name: 'Ed Hinchliffe',
      title: 'Co-Founding Director',
    },
    {
      photo: getImage(data.profileImages, 'dan.jpg'),
      name: 'Daniel Shipp',
      title: 'Co-Founding Director',
    },
    {
      photo: getImage(data.profileImages, 'kris.jpg'),
      name: 'Kris Goddard',
      title: 'Front End Developer',
    },
    {
      photo: getImage(data.profileImages, 'chris.jpg'),
      name: 'Chris Hatherall',
      title: 'Back End Developer',
    },
    {
      photo: getImage(data.profileImages, 'laszlo.jpg'),
      name: 'Laszlo Fulop',
      title: 'Front End Developer',
    },
  ];
  return (
    <div>
      <Helmet title="About Pixelnebula" />
      <HeroSpace
        title="About Us"
        // TODO: this was made up afaik. Feedback from Alex.
        subtitle="We make digital signage that helps you communicate simply, beautifully."
        image={{
          fluid: data.heroImage.fluid,
          alt: 'Line drawing of office space',
          className: styles.heroGraphic,
        }}
        extraChildren={
          <div className={combine(flex.jcc, styles.heroText)}>
            <h6 className={txt.purple}>
              Every day, digital signs driven by Flare are seen by millions of
              people.
            </h6>
            <p className={txt.darkGrey}>
              Since 2014 we’ve been helping our clients to communicate; sharing
              brand stories with end customers, keeping employees in the know,
              and delivering essential information to the public. We work with
              global giants like Vodafone, Centrica, and Elis and smaller
              outfits such as MoneySuperMarket and Spice Shack. We’re growing
              rapidly, but we’re still agile enough to react to our customers’
              needs. So, if you’ve got grand plans for the information you want
              to share, or something specific you’re looking for we’re happy to
              explore how we can help you make your ideas a reality.
            </p>
            <h6 className={combine(styles.traitsHeader, txt.purple)}>WE ARE</h6>
            <div className={combine(flex.row, flex.jcc, styles.traitsWrapper)}>
              <ul
                className={combine(
                  flex.row,
                  flex.jcse,
                  flex[1],
                  lists.unformatted
                )}
              >
                {traits.map((t, i) => (
                  <li
                    key={i}
                    className={combine(
                      styles.trait,
                      flex.col,
                      flex.jcsb,
                      flex.aic
                    )}
                  >
                    <Img {...t.icon} alt={t.alt} />
                    <p
                      className={combine(styles.traitText, txt.blue, txt.bold)}
                    >
                      {t.text}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
      />

      <ContentWrapper backgroundClasses={bg.lightBlue}>
        <h5 className={txt.center}>Our Team</h5>
        <ul
          className={combine(lists.unformatted, flex.jcc, flex.row, flex.wrap)}
        >
          {team.map((t, i) => (
            <li
              key={i}
              className={combine(styles.teamMember, flex.col, txt.center)}
            >
              <Img className={styles.userProfile} {...t.photo} alt={t.name} />
              <h5 className={combine(txt.uppercase, txt.bold, txt.darkGrey)}>
                {t.name}
              </h5>
              <h6 className={combine(txt.uppercase, txt.lighter, txt.darkGrey)}>
                {t.title}
              </h6>
            </li>
          ))}
        </ul>
      </ContentWrapper>

      {/* <ContentWrapper variant="dark">
        <h5 className={combine(txt.center, txt.white)}>
          Customer testimonials
        </h5>
      </ContentWrapper> */}
      <ContentWrapper backgroundClasses={bg.white}>
        <h5 className={txt.center}>Companies using our products</h5>
        <WrappedImage className={styles.clientList} {...data.clientsImage} />
      </ContentWrapper>
    </div>
  );
};

export default AboutUs;

export const pageQuery = graphql`
  query aboutAssets {
    profileImages: allImageSharp(
      filter: {
        fluid: { originalName: { regex: "/(ed|dan|kris|chris|laszlo)[.]jpg/" } }
      }
    ) {
      edges {
        node {
          fixed(width: 176, quality: 85) {
            name: originalName
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    traitIcons: allImageSharp(
      filter: {
        fluid: {
          originalName: {
            regex: "/(lightbulb|magnet|clipboard|bubbles)[.]png/"
          }
        }
      }
    ) {
      edges {
        node {
          fixed(width: 68, quality: 85) {
            name: originalName
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
    heroImage: imageSharp(
      fluid: { originalName: { regex: "/au-hero-graphic[.]png/" } }
    ) {
      fluid(maxWidth: 1330, quality: 85, traceSVG: { color: "#ffffff" }) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
    clientsImage: imageSharp(
      fluid: { originalName: { regex: "/clients[.]png/" } }
    ) {
      fluid(maxWidth: 1330, quality: 85) {
        name: originalName
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
